
      import pug from 'pug-runtime';

      function homeSlidersObsession(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (episode, getUniqueId, kind, obsession, people, placeholderId, printDate, resimg, slider, sliderId, toTextOnly) {
      kind = slider.kind
sliderId = getUniqueId()
pug_html = pug_html + "\u003Cdiv" + (" class=\"slider my-5\""+pug.attr("id", sliderId, true, false)+pug.attr("data-une-element-area", true, true, false)) + "\u003E\u003Cdiv class=\"d-flex align-items-start\"\u003E\u003Cinput" + (" type=\"hidden\" name=\"kind\""+pug.attr("value", kind, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"obsession\""+pug.attr("value", slider.obsession.id, true, false)) + "\u002F\u003E\u003Cdiv" + (" class=\"card position-relative p-4 flex-grow-1\""+pug.attr("style", pug.style("background-color: " + (kind === "PeopleObsessionSlider" ? slider.obsession.color.light : "#f8f9fa") + "; border-color: " + (kind === "PeopleObsessionSlider" ? slider.obsession.color.main : "#6c757d")), true, false)) + "\u003E";
if (kind === "PeopleObsessionSlider") {
pug_html = pug_html + "\u003Ch4 class=\"mb-4\"\u003ELes personnages de «&nbsp;" + (pug.escape(null == (pug_interp = slider.obsession.title) ? "" : pug_interp)) + "&nbsp;»\u003C\u002Fh4\u003E";
}
else
if (kind === "EpisodesObsessionSlider") {
pug_html = pug_html + "\u003Ch4 class=\"mb-4\"\u003E" + (pug.escape(null == (pug_interp = slider.obsession.title) ? "" : pug_interp)) + "\u003C\u002Fh4\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"slider-container card-deck-wrapper\"\u003E\u003Cdiv class=\"row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xxl-5 g-3 js-deck\"\u003E";
// iterate slider.elements
;(function(){
  var $$obj = slider.elements;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var element = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"col\"\u003E";
switch (kind){
case "PeopleObsessionSlider":
obsession = slider.obsession
people    = element
pug_html = pug_html + "\u003Cdiv class=\"card media-card card-small\"\u003E\u003Cinput" + (" type=\"hidden\" name=\"obsession\""+pug.attr("value", obsession.id, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"people\""+pug.attr("value", people.id, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", people.id, true, false)) + "\u002F\u003E\u003Cimg" + (" class=\"card-img-top img-fluid\""+pug.attr("src", resimg(people.img, "840"), true, false)+pug.attr("alt", people.alt, true, false)) + "\u002F\u003E\u003Cdiv class=\"card-img-overlay p-2\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E";
if (!(slider && slider.obsession)) {
pug_html = pug_html + "\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fas fa-times\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Ca" + (" class=\"hover-underline link-light\""+pug.attr("href", people.endpoint + "/" + people.id, true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = people.title) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E\u003Cp class=\"card-text text-white\"\u003E" + (null == (pug_interp = people.subtitle) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
  break;
case "EpisodesObsessionSlider":
episode   = element
pug_html = pug_html + "\u003Cdiv class=\"card episode-card card-small position-relative rounded\"\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"Episode\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", episode.id, true, false)) + "\u002F\u003E";
if (episode.type === "sans-image") {
pug_html = pug_html + "\u003Cdiv class=\"card-body bg-info rounded\"\u003E\u003C\u002Fdiv\u003E";
}
else
if (episode.image) {
pug_html = pug_html + "\u003Cimg" + (" class=\"card-img-top img-fluid\""+pug.attr("src", (episode.image.files ? episode.image.files[0] : resimg(episode.image.img, "840")), true, false)+pug.attr("alt", toTextOnly(episode.image.title), true, false)) + "\u002F\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"card-img-overlay p-2\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E";
if (!(slider && slider.obsession)) {
pug_html = pug_html + "\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fas fa-times\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"btn-group\"\u003E";
if (episode.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = episode.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Ca" + (" class=\"hover-underline link-light\""+pug.attr("href", episode.endpoint + "/" + episode.id, true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = episode.title) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E";
if (episode.date) {
pug_html = pug_html + "\u003Cp class=\"card-text text-white\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(episode.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text text-danger\"\u003ENon publié\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"alert-flag w-100 position-absolute bottom-0\"\u003E\u003Csmall class=\"w-100 p-1 bg-warning text-center text-white\"\u003EÉlément non visible (limite&nbsp;: 12 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
  break;
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var element = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"col\"\u003E";
switch (kind){
case "PeopleObsessionSlider":
obsession = slider.obsession
people    = element
pug_html = pug_html + "\u003Cdiv class=\"card media-card card-small\"\u003E\u003Cinput" + (" type=\"hidden\" name=\"obsession\""+pug.attr("value", obsession.id, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"people\""+pug.attr("value", people.id, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", people.id, true, false)) + "\u002F\u003E\u003Cimg" + (" class=\"card-img-top img-fluid\""+pug.attr("src", resimg(people.img, "840"), true, false)+pug.attr("alt", people.alt, true, false)) + "\u002F\u003E\u003Cdiv class=\"card-img-overlay p-2\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E";
if (!(slider && slider.obsession)) {
pug_html = pug_html + "\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fas fa-times\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Ca" + (" class=\"hover-underline link-light\""+pug.attr("href", people.endpoint + "/" + people.id, true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = people.title) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E\u003Cp class=\"card-text text-white\"\u003E" + (null == (pug_interp = people.subtitle) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
  break;
case "EpisodesObsessionSlider":
episode   = element
pug_html = pug_html + "\u003Cdiv class=\"card episode-card card-small position-relative rounded\"\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"Episode\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", episode.id, true, false)) + "\u002F\u003E";
if (episode.type === "sans-image") {
pug_html = pug_html + "\u003Cdiv class=\"card-body bg-info rounded\"\u003E\u003C\u002Fdiv\u003E";
}
else
if (episode.image) {
pug_html = pug_html + "\u003Cimg" + (" class=\"card-img-top img-fluid\""+pug.attr("src", (episode.image.files ? episode.image.files[0] : resimg(episode.image.img, "840")), true, false)+pug.attr("alt", toTextOnly(episode.image.title), true, false)) + "\u002F\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"card-img-overlay p-2\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E";
if (!(slider && slider.obsession)) {
pug_html = pug_html + "\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fas fa-times\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"btn-group\"\u003E";
if (episode.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = episode.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Ca" + (" class=\"hover-underline link-light\""+pug.attr("href", episode.endpoint + "/" + episode.id, true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = episode.title) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E";
if (episode.date) {
pug_html = pug_html + "\u003Cp class=\"card-text text-white\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(episode.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text text-danger\"\u003ENon publié\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"alert-flag w-100 position-absolute bottom-0\"\u003E\u003Csmall class=\"w-100 p-1 bg-warning text-center text-white\"\u003EÉlément non visible (limite&nbsp;: 12 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
  break;
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"btn-group-vertical rounded-end\"\u003E\u003Cbutton" + (" class=\"btn btn-danger rounded-0\""+" type=\"button\""+pug.attr("data-une-element-id", sliderId, true, false)) + "\u003E\u003Ci class=\"fas fa-times\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cbutton" + (" class=\"btn btn-success btn-edit text-light rounded-0\""+" type=\"button\" data-bs-toggle=\"modal\" data-bs-target=\"#add-une-element\""+pug.attr("data-une-element-id", sliderId, true, false)+pug.attr("data-une-element-file", slider.obsession.title, true, false)+" data-une-element-kind=\"une-obsession-slider\""+pug.attr("data-une-element-children", (slider.kind === "PeopleObsessionSlider" ? "elements-people" : "elements-articles"), true, false)+pug.attr("data-une-element-length", slider.elements.length, true, false)) + "\u003E\u003Ci class=\"fas fa-edit text-white\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cbutton" + (" class=\"btn btn-primary btn-arrow text-light rounded-0\""+" type=\"button\" data-arrow-direction=\"up\""+pug.attr("data-une-element-id", sliderId, true, false)) + "\u003E\u003Ci class=\"fas fa-arrow-up text-white\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cbutton" + (" class=\"btn btn-primary btn-arrow text-light rounded-0\""+" type=\"button\" data-arrow-direction=\"down\""+pug.attr("data-une-element-id", sliderId, true, false)) + "\u003E\u003Ci class=\"fas fa-arrow-down text-white\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"card-deck-wrapper my-5 d-flex justify-content-center\"\u003E";
placeholderId = sliderId
pug_html = pug_html + "\u003Cdiv" + (" class=\"btn add-button w-auto border-0\""+" data-bs-toggle=\"modal\" data-bs-target=\"#add-une-element\""+pug.attr("data-placeholder-id", placeholderId, true, false)) + "\u003E\u003Ci class=\"fas fa-plus-circle fa-3x text-primary\"\u003E\u003C\u002Fi\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "episode" in locals_for_with ?
        locals_for_with.episode :
        typeof episode !== 'undefined' ? episode : undefined, "getUniqueId" in locals_for_with ?
        locals_for_with.getUniqueId :
        typeof getUniqueId !== 'undefined' ? getUniqueId : undefined, "kind" in locals_for_with ?
        locals_for_with.kind :
        typeof kind !== 'undefined' ? kind : undefined, "obsession" in locals_for_with ?
        locals_for_with.obsession :
        typeof obsession !== 'undefined' ? obsession : undefined, "people" in locals_for_with ?
        locals_for_with.people :
        typeof people !== 'undefined' ? people : undefined, "placeholderId" in locals_for_with ?
        locals_for_with.placeholderId :
        typeof placeholderId !== 'undefined' ? placeholderId : undefined, "printDate" in locals_for_with ?
        locals_for_with.printDate :
        typeof printDate !== 'undefined' ? printDate : undefined, "resimg" in locals_for_with ?
        locals_for_with.resimg :
        typeof resimg !== 'undefined' ? resimg : undefined, "slider" in locals_for_with ?
        locals_for_with.slider :
        typeof slider !== 'undefined' ? slider : undefined, "sliderId" in locals_for_with ?
        locals_for_with.sliderId :
        typeof sliderId !== 'undefined' ? sliderId : undefined, "toTextOnly" in locals_for_with ?
        locals_for_with.toTextOnly :
        typeof toTextOnly !== 'undefined' ? toTextOnly : undefined));
    ;;return pug_html;}

      export default homeSlidersObsession;
    